import { SvgIcon } from "@mui/material"

const InstagramIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M21.4095 4.99561C20.5043 4.99561 19.772 5.72816 19.772 6.63369C19.772 7.53921 20.5043 8.27177 21.4095 8.27177C22.3147 8.27177 23.0469 7.53921 23.0469 6.63369C23.0469 5.73834 22.3147 4.99561 21.4095 4.99561Z" fill={props.color || "#FFFFFF"}/>
      <path d="M14.1067 7.10181C10.3028 7.10181 7.21094 10.1948 7.21094 14.0001C7.21094 17.8053 10.3028 20.8983 14.1067 20.8983C17.9105 20.8983 21.0024 17.8053 21.0024 14.0001C21.0024 10.1948 17.9105 7.10181 14.1067 7.10181ZM14.1067 18.4158C11.6759 18.4158 9.69259 16.4317 9.69259 14.0001C9.69259 11.5684 11.6759 9.58436 14.1067 9.58436C16.5375 9.58436 18.5208 11.5684 18.5208 14.0001C18.5208 16.4317 16.5477 18.4158 14.1067 18.4158Z" fill={props.color || "#FFFFFF"}/>
      <path d="M19.5786 28H8.41119C3.77334 28 0 24.2253 0 19.5858V8.41424C0 3.77471 3.77334 0 8.41119 0H19.5888C24.2267 0 28 3.77471 28 8.41424V19.5959C27.9898 24.2253 24.2165 28 19.5786 28ZM8.41119 2.63517C5.22775 2.63517 2.63422 5.22965 2.63422 8.41424V19.5959C2.63422 22.7805 5.22775 25.375 8.41119 25.375H19.5888C22.7722 25.375 25.3658 22.7805 25.3658 19.5959V8.41424C25.3658 5.22965 22.7722 2.63517 19.5888 2.63517H8.41119Z" fill={props.color || "#FFFFFF"}/>
    </SvgIcon>
  )
}

export default InstagramIcon;
