import { SvgIcon } from "@mui/material"

const CloseIcon = (props: any) => {
  return (
    <SvgIcon {...props} fill="none">
      <style type="text/css">
        {
          `
            .st0{fill:none;stroke:${props.color || '#FFFFFF'};stroke-width:1.25;stroke-miterlimit:10;}
            .st1{fill:${props.color || '#FFFFFF'};}
            .st2{fill:none;stroke:${props.color || '#FFFFFF'};stroke-width:1.25;}
            .st3{fill:#010202;}
          `
        }
      </style>
      <rect x="-6.2" y="50.7" width="154.75" height="29.92"/>
      <rect x="279.13" y="0" width="24" height="24"/>
      <rect y="0" width="24" height="24"/>
      <rect x="75.04" y="0" width="24" height="24"/>
      <rect x="242.57" y="0" width="24" height="24"/>
      <rect x="209.11" y="0" width="24" height="24"/>
      <rect x="177.6" y="0" width="24" height="24"/>
      <rect x="144.99" y="0" width="24" height="24"/>
      <rect x="111.64" y="0" width="24" height="24"/>
      <g>
        <g>
          <line className="st0" x1="123.64" y1="24" x2="123.64" y2="19.2"/>
          <line className="st0" x1="123.64" y1="4.79" x2="123.64" y2="0"/>
          <line className="st0" x1="116.44" y1="12" x2="111.64" y2="12"/>
          <line className="st0" x1="135.64" y1="12" x2="130.85" y2="12"/>
          <line className="st0" x1="118.71" y1="17.23" x2="115.64" y2="20.48"/>
          <line className="st0" x1="131.67" y1="3.52" x2="128.6" y2="6.77"/>
          <line className="st0" x1="118.7" y1="6.76" x2="115.64" y2="3.52"/>
          <line className="st0" x1="131.67" y1="20.48" x2="128.59" y2="17.22"/>
        </g>
        <g>
          <path className="st1" d="M123.64,4.79c1.91,0,3.66,0.75,4.95,1.98c1.39,1.31,2.25,3.17,2.25,5.23s-0.87,3.92-2.26,5.22
            c-1.29,1.23-3.03,1.98-4.94,1.98c-1.92,0-3.66-0.75-4.93-1.97c-1.41-1.3-2.27-3.16-2.27-5.23s0.86-3.93,2.26-5.24
            C119.97,5.53,121.71,4.79,123.64,4.79 M123.64,15.44c0.91,0,1.75-0.36,2.37-0.95c0.66-0.62,1.07-1.51,1.07-2.49
            s-0.41-1.87-1.06-2.5c-0.62-0.59-1.47-0.95-2.38-0.95c-0.93,0-1.75,0.35-2.37,0.94c-0.67,0.63-1.07,1.52-1.07,2.51
            s0.41,1.88,1.08,2.5C121.89,15.08,122.72,15.44,123.64,15.44 M123.64,3.54c-2.2,0-4.26,0.82-5.81,2.32
            c-1.7,1.59-2.64,3.77-2.64,6.14c0,2.34,0.97,4.58,2.67,6.15c1.54,1.48,3.6,2.3,5.78,2.3c2.17,0,4.23-0.83,5.8-2.33
            c1.68-1.57,2.65-3.81,2.65-6.12c0-2.32-0.96-4.56-2.64-6.14C127.88,4.37,125.82,3.54,123.64,3.54L123.64,3.54z M123.64,14.19
            c-0.56,0-1.09-0.21-1.5-0.6c-0.45-0.42-0.69-0.98-0.69-1.59c0-0.62,0.24-1.19,0.68-1.6c0.41-0.39,0.94-0.6,1.51-0.6
            c0.56,0,1.11,0.22,1.52,0.61c0.42,0.41,0.67,0.99,0.67,1.59s-0.25,1.18-0.68,1.58C124.74,13.97,124.21,14.19,123.64,14.19
            L123.64,14.19z"/>
        </g>
      </g>
      <g>
        <polyline className="st2" points="79.14,6.97 79.14,19.9 92.06,19.9 	"/>
        <g>
          <path className="st1" d="M93.69,5.35v10.42H83.26V5.35H93.69 M94.94,4.1H82.01v12.92h12.92V4.1H94.94z"/>
        </g>
      </g>
      <path className="st1" d="M262.22,5.13c-1.45-0.65-2.97-1.12-4.54-1.38c-0.2,0.34-0.42,0.81-0.58,1.18c-1.66-0.24-3.36-0.24-5.03,0
        c-0.18-0.4-0.38-0.79-0.59-1.18c-1.58,0.26-3.11,0.73-4.55,1.38c-2.87,4.21-3.64,8.31-3.26,12.35c1.68,1.23,3.56,2.16,5.57,2.77
        c0.45-0.6,0.85-1.24,1.19-1.91c-0.65-0.24-1.28-0.54-1.88-0.88c0.16-0.11,0.31-0.24,0.45-0.35c3.53,1.64,7.62,1.64,11.14,0
        c0.15,0.12,0.31,0.24,0.46,0.35c-0.6,0.35-1.23,0.65-1.88,0.89c0.34,0.67,0.74,1.3,1.19,1.9c2.01-0.6,3.89-1.53,5.57-2.77
        C265.94,12.8,264.71,8.73,262.22,5.13z M250.92,15c-1.08,0-1.98-0.98-1.98-2.18s0.87-2.19,1.98-2.19s1.99,0.98,1.98,2.18
        C252.89,14.02,252.03,15,250.92,15z M258.23,15c-1.08,0-1.98-0.98-1.98-2.18s0.87-2.19,1.98-2.19c1.11,0,1.99,0.98,1.98,2.18
        C260.2,14.02,259.34,15,258.23,15z"/>
      <path className="st1" d="M231.23,5.58c-0.65,0.29-1.37,0.48-2.07,0.59c0.33-0.05,0.82-0.66,1.01-0.91c0.3-0.37,0.55-0.79,0.68-1.25
        c0.02-0.04,0.03-0.08-0.01-0.11c-0.05-0.02-0.08-0.01-0.11,0.01c-0.78,0.42-1.59,0.73-2.45,0.94c-0.07,0.02-0.12,0-0.17-0.05
        c-0.07-0.08-0.14-0.16-0.21-0.22c-0.36-0.31-0.75-0.55-1.19-0.73c-0.57-0.23-1.2-0.33-1.82-0.29c-0.6,0.04-1.19,0.21-1.72,0.49
        c-0.53,0.28-1.01,0.67-1.39,1.14c-0.39,0.48-0.68,1.07-0.83,1.67c-0.14,0.59-0.13,1.17-0.05,1.77c0.02,0.1,0.01,0.11-0.08,0.1
        c-3.36-0.51-6.15-1.71-8.4-4.3c-0.1-0.11-0.15-0.11-0.23,0.01c-0.99,1.49-0.51,3.88,0.73,5.06c0.17,0.16,0.33,0.32,0.52,0.45
        c-0.07,0.02-0.89-0.08-1.62-0.45c-0.1-0.06-0.15-0.03-0.16,0.08c-0.01,0.16,0.01,0.31,0.03,0.48c0.19,1.51,1.23,2.91,2.65,3.45
        c0.17,0.07,0.35,0.14,0.54,0.17c-0.32,0.07-0.66,0.12-1.59,0.05c-0.11-0.02-0.16,0.04-0.11,0.15c0.7,1.92,2.21,2.49,3.34,2.81
        c0.15,0.03,0.3,0.03,0.46,0.06c-0.01,0.02-0.02,0.02-0.03,0.03c-0.38,0.57-1.68,1.01-2.28,1.22c-1.1,0.39-2.3,0.56-3.46,0.45
        c-0.19-0.03-0.23-0.02-0.28,0c-0.05,0.03-0.01,0.08,0.05,0.13c0.23,0.16,0.47,0.29,0.72,0.43c0.74,0.39,1.51,0.71,2.32,0.93
        c4.16,1.16,8.85,0.31,11.98-2.82c2.46-2.46,3.31-5.83,3.31-9.23c0-0.14,0.16-0.2,0.25-0.27c0.64-0.48,1.16-1.06,1.63-1.71
        c0.1-0.14,0.1-0.27,0.1-0.33c0-0.01,0-0.02,0-0.02C231.32,5.53,231.31,5.55,231.23,5.58z"/>
      <path className="st1" d="M191.26,20.44v-7.39h2.79l0.67-3.06h-3.46V8.02c0-0.83,0.47-1.65,1.95-1.65h1.51v-2.6c0,0-1.37-0.21-2.68-0.21
        c-2.73,0-4.52,1.46-4.52,4.11V10h-3.05v3.06h3.05v7.39L191.26,20.44L191.26,20.44z"/>
      <g>
        <path className="st1" d="M161.46,6.57c-0.55,0-0.99,0.44-0.99,0.99s0.44,0.99,0.99,0.99c0.55,0,0.99-0.44,0.99-0.99
          C162.44,7.02,162,6.57,161.46,6.57z"/>
        <path className="st1" d="M157.05,7.84c-2.29,0-4.16,1.87-4.16,4.16s1.86,4.16,4.16,4.16c2.29,0,4.16-1.87,4.16-4.16
          S159.35,7.84,157.05,7.84z M157.05,14.66c-1.47,0-2.66-1.2-2.66-2.66s1.2-2.66,2.66-2.66c1.47,0,2.66,1.2,2.66,2.66
          S158.52,14.66,157.05,14.66z"/>
        <path className="st1" d="M160.35,20.44h-6.73c-2.8,0-5.07-2.28-5.07-5.07V8.63c0-2.8,2.28-5.07,5.07-5.07h6.74
          c2.8,0,5.07,2.28,5.07,5.07v6.74C165.42,18.17,163.15,20.44,160.35,20.44z M153.62,5.15c-1.92,0-3.48,1.56-3.48,3.48v6.74
          c0,1.92,1.56,3.48,3.48,3.48h6.74c1.92,0,3.48-1.56,3.48-3.48V8.63c0-1.92-1.56-3.48-3.48-3.48H153.62z"/>
      </g>
      <g>
        <circle className="st1" cx="291.13" cy="12" r="11.6"/>
        <path className="st3" d="M297.26,12l-9.95,5.74V6.26L297.26,12z"/>
      </g>
      <rect x="38.66" y="0" width="24" height="24"/>
      <g>
        <line className="st2" x1="59.28" y1="6.8" x2="42.04" y2="6.8"/>
        <path className="st2" d="M42.04,12h17.23"/>
        <line className="st2" x1="42.04" y1="17.21" x2="59.28" y2="17.21"/>
      </g>
      <g>
        <g>
          <line className="st0" x1="123.64" y1="74.7" x2="123.64" y2="69.9"/>
          <line className="st0" x1="123.64" y1="55.49" x2="123.64" y2="50.7"/>
          <line className="st0" x1="116.44" y1="62.7" x2="111.64" y2="62.7"/>
          <line className="st0" x1="135.64" y1="62.7" x2="130.85" y2="62.7"/>
          <line className="st0" x1="118.71" y1="67.93" x2="115.64" y2="71.18"/>
          <line className="st0" x1="131.67" y1="54.22" x2="128.6" y2="57.47"/>
          <line className="st0" x1="118.7" y1="57.46" x2="115.64" y2="54.22"/>
          <line className="st0" x1="131.67" y1="71.18" x2="128.59" y2="67.92"/>
        </g>
        <g>
          <path className="st1" d="M123.64,55.49c1.91,0,3.66,0.75,4.95,1.98c1.39,1.31,2.25,3.17,2.25,5.23s-0.87,3.92-2.26,5.22
            c-1.29,1.23-3.03,1.98-4.94,1.98c-1.92,0-3.66-0.75-4.93-1.97c-1.41-1.3-2.27-3.16-2.27-5.23c0-2.07,0.86-3.93,2.26-5.24
            C119.97,56.23,121.71,55.49,123.64,55.49 M123.64,66.14c0.91,0,1.75-0.36,2.37-0.95c0.66-0.62,1.07-1.51,1.07-2.49
            c0-0.98-0.41-1.87-1.06-2.5c-0.62-0.59-1.47-0.95-2.38-0.95c-0.93,0-1.75,0.35-2.37,0.94c-0.67,0.63-1.07,1.52-1.07,2.51
            s0.41,1.88,1.08,2.5C121.89,65.78,122.72,66.14,123.64,66.14 M123.64,54.24c-2.2,0-4.26,0.82-5.81,2.32
            c-1.7,1.59-2.64,3.77-2.64,6.14c0,2.34,0.97,4.58,2.67,6.15c1.54,1.48,3.6,2.3,5.78,2.3c2.17,0,4.23-0.83,5.8-2.33
            c1.68-1.57,2.65-3.81,2.65-6.12c0-2.32-0.96-4.56-2.64-6.14C127.88,55.07,125.82,54.24,123.64,54.24L123.64,54.24z M123.64,64.89
            c-0.56,0-1.09-0.21-1.5-0.6c-0.45-0.42-0.69-0.98-0.69-1.59c0-0.62,0.24-1.19,0.68-1.6c0.41-0.39,0.94-0.6,1.51-0.6
            c0.56,0,1.11,0.22,1.52,0.61c0.42,0.41,0.67,0.99,0.67,1.59s-0.25,1.18-0.68,1.58C124.74,64.68,124.21,64.89,123.64,64.89
            L123.64,64.89z"/>
        </g>
      </g>
      <g>
        <polyline className="st2" points="79.14,57.68 79.14,70.6 92.06,70.6 	"/>
        <g>
          <path className="st1" d="M93.69,56.06v10.42H83.26V56.06H93.69 M94.94,54.81H82.01v12.92h12.92V54.81H94.94z"/>
        </g>
      </g>
      <g>
        <line className="st2" x1="59.28" y1="57.5" x2="42.04" y2="57.5"/>
        <path className="st2" d="M42.04,62.71h17.23"/>
        <line className="st2" x1="42.04" y1="67.91" x2="59.28" y2="67.91"/>
      </g>
      <g id="Layer_2_1_">
      </g>
      <line className="st2" x1="22.19" y1="1.81" x2="1.81" y2="22.18"/>
      <line className="st2" x1="22.19" y1="22.18" x2="1.81" y2="1.81"/>
      <line className="st2" x1="20.09" y1="54.62" x2="3.91" y2="70.8"/>
      <line className="st2" x1="20.09" y1="70.8" x2="3.91" y2="54.62"/>
    </SvgIcon>
  )
}

export default CloseIcon;
